import { ReactNode } from "react";
import Menu from "../Menu";

import { Row, Column } from "./styled";

export interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => (
  <Row>
    <Menu />
    <Column>{children}</Column>
  </Row>
);

export default Layout;
