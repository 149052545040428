import styled from "styled-components";

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.mainFont};

  span {
    display: block;
    font-size: 14px;
    line-height: 100%;
    font-weight: 700;
    margin: 0;
  }

  svg {
    width: 40px;
    height: auto;
    margin: 0;
    padding: 0;
  }
`;
