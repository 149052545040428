import { action, makeAutoObservable } from "mobx";
import ChatStore from "./chat";

export const SERVER_URL = "wss://api.meet2code.com/editor";

class WebsocketConnection {
  constructor() {
    makeAutoObservable(this);
  }

  clientID = 0;

  connection: WebSocket | null = null;

  isConnected = false;

  pingInterval: any = null;

  sendToServer = (msg: any): void => {
    const msgJSON = JSON.stringify(msg);
    if (this.connection) {
      this.connection.send(msgJSON);
    }
  };

  setUsername = (userName: string): void =>
    this.sendToServer({
      name: userName,
      id: this.clientID,
      type: "username",
    });

  pingServer = () => {
    this.sendToServer({ type: "ping" });
  };

  setClientId = (id: number) => {
    this.clientID = id;
  };

  connect = (): void => {
    if (!this.connection) {
      this.connection = new WebSocket(SERVER_URL, "json");
    }
    this.connection.onopen = (): void => this.setIsConnected(true);

    this.connection.onerror = (): void => this.setIsConnected(false);

    this.connection.onclose = (): void => this.setIsConnected(false);

    this.connection.onmessage = (evt): void => {
      const msg = JSON.parse(evt.data);
      const { type, id, payload } = msg;
      switch (type) {
        case "id":
          this.setClientId(id);
          this.setUsername("support-admin");
          break;
        case "new-message":
          ChatStore.pushMessageToList(payload);
          break;
        default:
          break;
      }
    };

    this.pingInterval = setInterval(this.pingServer, 15000);
  };

  close = (): void => {
    if (this.connection) {
      this.connection.close();
      this.connection = null;
    }
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
  };

  @action setIsConnected = (value: boolean): void => {
    this.isConnected = value;
  };
}

export default new WebsocketConnection();
