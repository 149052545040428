import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "shared/lib/useStore";
import stores from "shared/stores";
import RoutesManager from "routes";
import ErrorBoundary from "shared/ui/ErrorBoundary";
import "./app/styles/base.css";
import "./app/styles/fontFace.css";
import "./app/styles/normalize.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <BrowserRouter>
    <ErrorBoundary>
      <Provider value={stores}>
        <RoutesManager />
      </Provider>
    </ErrorBoundary>
  </BrowserRouter>
);
