import { observer } from "mobx-react-lite";
import { ThemeProvider } from "styled-components";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useStore } from "shared/lib/useStore";
import { theme } from "shared/constants/theme";
import Notifications from "shared/ui/Notifications";
import { ROUTES } from "shared/constants/routes";
import { ConfigProvider, theme as antdTheme } from "antd";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import Login from "../pages/Login";

const RoutesManager = () => {
  const {
    auth: { isAuthorized },
    theme: { isDarkTheme },
  } = useStore();
  const Route = isAuthorized ? PrivateRoutes : PublicRoutes;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate(ROUTES.LOGIN);
    }
  }, [isAuthorized]);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkTheme
          ? antdTheme.darkAlgorithm
          : antdTheme.defaultAlgorithm,
        token: {
          colorBgContainer: theme(isDarkTheme).mainBg,
        },
      }}
    >
      {" "}
      <ThemeProvider theme={theme(isDarkTheme)}>
        <Notifications />
        {isAuthorized ? <Route /> : <Login />}
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default observer(RoutesManager);
