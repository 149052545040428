import { action, makeAutoObservable } from "mobx";
import NotificationStore from "./notification";

class Auth {
  constructor() {
    makeAutoObservable(this);
  }

  isAuthorized = !!localStorage.getItem("api_token");

  @action login = (token: string): void => {
    NotificationStore.pushToNotificationsList({
      description: "Hard work started 😎",
    });
    localStorage.setItem("api_token", token);
    this.setIsAuthorized(true);
  };

  @action logout = () => {
    this.setIsAuthorized(false);
    localStorage.removeItem("api_token");
  };

  @action setIsAuthorized = (state: boolean) => {
    this.isAuthorized = state;
  };
}

export default new Auth();
