import { POSTRequest } from "./createRequest";

export const getApiToken = (
  userEmail: string,
  password: string,
  switchSelectedCompanyId?: string
) =>
  POSTRequest<{ access_token: string }>("/auth", {
    userEmail,
    password,
    switchSelectedCompanyId,
  });
