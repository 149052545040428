import { Company } from "shared/types";
import { GETRequest, PATCHRequest } from "./createRequest";

const COMPANIES_ROUTE = "/admin-companies";

export const getCompaniesList = (queries?: string) =>
  GETRequest<Company[]>(`${COMPANIES_ROUTE}${queries ? `?${queries}` : ""}`);

export const getCompanyById = (companyId: string) =>
  GETRequest<Company>(`${COMPANIES_ROUTE}/${companyId}`);

export const updateCompany = ({
  companyId,
  comments,
  tags,
}: Pick<Company, "comments" | "companyId" | "tags">) =>
  PATCHRequest(`${COMPANIES_ROUTE}/${companyId}`, {
    comments,
    tags,
  });
