import SignIn from "./components/SignIn";

import { Container, FormWrap } from "./styled";

const Login = () => {
  return (
    <Container>
      <FormWrap>
        <SignIn />
      </FormWrap>
    </Container>
  );
};

export default Login;
