import { UserInfo } from "./user";

export enum Plans {
  FREE = "free",
  PRO = "pro",
  START = "start",
  TRIAL = "trial",
}

export enum Tags {
  needContact = "needContact",
  problem = "problem",
  waitingAnswer = "waitingAnswer",
}

export enum EmailSentType {
  any = "any",
}

export interface Comment {
  commentId: string;
  createdAt: number;
  value: string;
}

export interface CompanyBaseInfo {
  _id: string;
  avatar: string;
  brandLogo: string;
  lastSeen: number;
  name: string;
  ownerId: string;
  plan: Plans;
  planExpires: number;
  registrationAt: number;
  usersList: UserInfo[];
}

export interface Company {
  candidatesCount: number;
  comments: Comment[];
  companyId: string;
  companyInfo: CompanyBaseInfo;
  tags: Tags[];
  tasksCount: number;
}
