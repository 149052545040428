import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  overflow: hidden;
`;

export const LogoWrap = styled.div`
  display: flex;
  align-items: flex-start;
  svg {
    width: 40px;
    height: auto;
  }

  a {
    font-size: 22px;
    height: 40px;
  }
`;

export const FormWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.mainBg};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    width: 100%;
    padding: 40px 15px;
    align-items: flex-start;
  }
`;

export const Form = styled.form`
  background: ${({ theme }) => theme.cardBg};
  border-radius: 6px;
  padding: 20px 30px;
  width: 100%;
  max-width: 460px;
  margin: 0 0 50px 0;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

export const Field = styled.div`
  position: relative;
  margin: 0 0 16px 0;
  width: 100%;

  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    fill: #fff;
    width: 20px;
  }
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  border-radius: 6px;
  height: 44px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
  color: #fff;
  padding: 0 0 0 15px;
  transition: 0.1s;

  &::placeholder {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
    text-transform: capitalize;
  }

  &:focus {
    box-shadow: inset 0 -2px 0 0 #39df84;
  }
`;

export const Submit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 6px;
  background: #39df84;
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  margin: 20px 0 0 0;
`;

export const ErrorTitle = styled.span`
  display: block;
  margin: 0 0 10px 0;
  color: #f34c44;
  height: 20px;
`;
