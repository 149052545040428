import { action, makeAutoObservable } from "mobx";
import { createMessage, getConversationsList } from "../api/chat";
import { Conversation, Message } from "../types";
import { initialUserInfo } from "./initialData";

const initialConversation = {
  userId: "",
  messagesList: [],
  userInfo: initialUserInfo(),
};

class Chat {
  constructor() {
    makeAutoObservable(this);
  }

  isSending = false;

  activeConversation: Conversation = initialConversation;

  messagesList: Message[] = [];

  conversationsList: Conversation[] = [];

  @action fetchConversationsList = () => {
    getConversationsList().then((res) => {
      this.setConversationsList(res);
      if (this.activeConversation.userId) {
        this.setActiveConversation(this.activeConversation.userId);
      }
    });
  };

  @action setActiveConversation = (userId: string): void => {
    this.activeConversation =
      this.conversationsList.find((el) => el.userId === userId) ||
      initialConversation;
  };

  @action sendMessage = (newMessage: Message): void => {
    this.setIsSending(true);
    createMessage(newMessage)
      .then(() => this.pushMessageToList(newMessage))
      .finally(() => {
        this.setIsSending(false);
      });
  };

  @action pushMessageToList = (message: Message): void => {
    this.activeConversation.messagesList = [
      ...this.activeConversation.messagesList,
      message,
    ];
  };

  @action setConversationsList = (conversations: any[]): void => {
    this.conversationsList = conversations;
  };

  @action setIsSending = (value: boolean): void => {
    this.isSending = value;
  };
}

export default new Chat();
