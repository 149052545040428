import { configure } from "mobx";
import auth from "./auth";
import theme from "./theme";
import notification from "./notification";
import companies from "./companies";
import chat from "./chat";
import websocket from "./websocket";

configure({ enforceActions: "always" });

const Store = {
  auth,
  theme,
  notification,
  companies,
  chat,
  websocket,
} as const;

export type AppState = typeof Store;

export default Store;
