import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  color: ${({ theme }) => theme.mainFont};
  background: ${({ theme }) => theme.mainBg};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
