import { ChangeEvent, FC, FormEvent, useState } from "react";

import { Form, Field, Input, Submit, LogoWrap, ErrorTitle } from "../../styled";
import { useStore } from "../../../../shared/lib/useStore";
import { getApiToken } from "../../../../shared/api";
import Spinner from "../../../../shared/ui/Spinner";
import Logo from "../../../../shared/ui/Logo";

const SignIn: FC = () => {
  const { auth } = useStore();
  const savedEmail = document.cookie
    .split(";")
    .find((el) => el.includes("userEmail"))
    ?.split("=")[1];

  const [credential, setCredential] = useState({
    email: decodeURIComponent(savedEmail || ""),
    password: "",
  });
  const [error, setError] = useState("");
  const [isSending, setIsSending] = useState(false);

  const onLoginHandler = (ev: FormEvent) => {
    ev.preventDefault();
    if (!credential.email || !credential.password) {
      setError("Заполни все поля");
      return;
    }

    setIsSending(true);
    getApiToken(credential.email, credential.password)
      .then((res) => {
        auth.login(res.access_token);
      })
      .catch((err) => {
        setError(err.message);
        setIsSending(false);
      });
  };

  const setCredentialHandler =
    (type: string) =>
    (ev: ChangeEvent<HTMLInputElement>): void => {
      const { value } = ev.target;
      setCredential({
        ...credential,
        [type]: value,
      });
    };

  return (
    <Form onSubmit={onLoginHandler}>
      <LogoWrap>
        <Logo />
      </LogoWrap>
      <ErrorTitle>{error}</ErrorTitle>
      <Field>
        <Input
          defaultValue={credential.email}
          type="email"
          onChange={setCredentialHandler("email")}
          placeholder="Почта"
        />
      </Field>
      <Field>
        <Input
          type="password"
          onChange={setCredentialHandler("password")}
          placeholder="Пароль"
        />
      </Field>
      <Submit>
        {isSending && <Spinner />}
        Войти
      </Submit>
    </Form>
  );
};

export default SignIn;
