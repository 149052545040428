import { action, computed, makeAutoObservable } from "mobx";
import { getCompaniesList, getCompanyById, updateCompany } from "shared/api";
import { Company, Tags } from "shared/types";
import { initialCompany } from "./initialData";

export interface FilterQueries {
  limit: string;
  search: string;
  sortBy: string;
  tags: Tags | "";
}

class Companies {
  constructor() {
    makeAutoObservable(this);
  }

  companiesList: Company[] = [];

  isFetchingCompanies = true;

  selectedCompany = initialCompany();

  filterQueries: FilterQueries = {
    search: "",
    sortBy: "",
    tags: "",
    limit: "50",
  };

  @computed get transformedFilterQueries() {
    return Object.entries(this.filterQueries)
      .map((el) => !!el[1].length && `${el[0]}=${el[1]}`)
      .filter((el) => !!el)
      .join("&");
  }

  @action setFilterQueries = ({
    key,
    value,
  }: {
    key: keyof FilterQueries;
    value: any;
  }) => {
    // @ts-ignore
    this.filterQueries[key] = value;
    this.fetchCompaniesList();
  };

  @action setSelectedCompany = (company: Company) => {
    this.selectedCompany = company;
  };

  @action fetchCompanyById = (companyId: string) => {
    const targetCompany = this.companiesList.find(
      (el) => el.companyId === companyId
    );

    if (targetCompany) {
      this.setSelectedCompany(targetCompany);
    } else {
      getCompanyById(companyId).then((res) => {
        this.setSelectedCompany(res);
      });
    }
  };

  @action fetchCompaniesList = () => {
    this.setIsFetchingCompanies(true);
    getCompaniesList(this.transformedFilterQueries)
      .then((list) => {
        this.setCompaniesList(list);
      })
      .finally(() => {
        this.setIsFetchingCompanies(false);
      });
  };

  @action setCompaniesList = (list: Company[]) => {
    this.companiesList = list;
  };

  @action setIsFetchingCompanies = (value: boolean) => {
    this.isFetchingCompanies = value;
  };

  @action updateCompanyInfo = ({
    comments,
    companyId,
    tags,
  }: Pick<Company, "comments" | "companyId" | "tags">) => {
    const targetCompany = this.companiesList.find(
      (el) => el.companyId === companyId
    );

    if (targetCompany) {
      updateCompany({ comments, companyId, tags }).then(() => {
        targetCompany.comments = comments;
        targetCompany.tags = tags;
      });
    }
  };
}

export default new Companies();
